import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Calendar } from "grommet"
import { FormattedDate } from "react-intl"
import { Send } from "grommet-icons"
import MessageOfTheDayState from "./index.state"
import { debounce } from "../../../lib/debounce"

const Wrapper = styled.div``

const Title = styled.div`
  font-family: "Dancing Script", cursive;
  color: white;
  font-size: 28px;
  margin-bottom: 10px;
  line-height: 28px;
`

const Form = styled.div`
  > :first-child {
    background-color: white;
    min-height: 100px;
    border-radius: 8px;
    outline: none;
    padding: 14px;
  }
  > :nth-child(2) {
    margin-top: 10px;
    outline: none;
    border: none;
    border-radius: 8px;
    padding: 8px 15px;
    margin-left: 20px;
  }
`

const SelectedDate = styled.div`
  position: relative;
  height: 42px;
  display: flex;
  align-items: center;
  background-color: #a91346;
  border-radius: 8px;
  margin-top: 10px;
  padding: 0 12px;
  color: #fff;
  > :first-child {
    cursor: pointer;
    width: 100%;
  }
`

const StyledCalendar = styled(Calendar)`
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  color: #000;
  padding: 15px;
  width: 100%;
  transform: translateY(50px);
`

const Submit = styled.div`
  background-color: #a91346;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  width: auto;
  float: right;
  margin-top: 10px;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  > :first-child {
    margin-right: 10px;
  }
`

const CreateMOTD = props => {
  const valueInput = useRef()
  const authorInput = useRef()
  const { add } = MessageOfTheDayState.useContainer()
  const [calendarDisplayed, setCalendarDisplayed] = useState(false)
  const initialDate = new Date()
  initialDate.setDate(initialDate.getDate() + 1)
  initialDate.setHours(4, 30, 0, 0)
  const [date, setDate] = useState(initialDate.toISOString())

  useEffect(() => {
    let cachedValue = localStorage.getItem('cached-message')
    if (cachedValue) { valueInput.current.innerText = cachedValue }
  }, [])

  useEffect(() => {
    setCalendarDisplayed(false)
  }, [date])
  

  const debouncedSave = debounce(() => {
    localStorage.setItem('cached-message', valueInput.current.innerText)
  }, 300)

  const onTextChange = (e) => {
    debouncedSave(e)
  }

  const onSubmit = async () => {
    await add({
      date: new Date(date),
      value: valueInput.current.innerText,
      author: authorInput.current.value
    })
    valueInput.current.innerText = ''
    localStorage.removeItem('cached-message')
    authorInput.current.value = ''
  }

  return (
    <Wrapper>
      <Title>Nouveau message</Title>
      <Form>
        <div contentEditable ref={valueInput} onInput={onTextChange} />
        <input type="text" ref={authorInput} placeholder="Qui c'est?" />
        <div>
          <SelectedDate>
            <div onClick={() => setCalendarDisplayed(!calendarDisplayed)}>
              <FormattedDate value={date} />
            </div>
            {calendarDisplayed && (
              <StyledCalendar
                size="small"
                date={date}
                onSelect={date => setDate(date)}
              />
            )}
          </SelectedDate>
        </div>
        <Submit onClick={onSubmit}>
          <Send color="#fff" />
          Envoyer
        </Submit>
      </Form>
    </Wrapper>
  )
}

CreateMOTD.propTypes = {}

CreateMOTD.defaultProps = {}

export default CreateMOTD
