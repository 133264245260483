import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import MessageOfTheDayState from './index.state'
import styled, { css } from 'styled-components'
import { FormattedDate } from 'react-intl'
import EditableText from '../../UI/EditableText'
import { Trash } from 'grommet-icons'
import { colors } from '../../../theme'
import CurrentUserState from '../../../state/CurrentUserState'
import Loader from '../../UI/Loader'
import InfiniteScroll from 'react-infinite-scroller'

const Wrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  overflow-x: hidden;
`

const Content = styled.div`
  > * > * {
    margin-bottom: 25px;
  }
`

// Axelle: BQpXcjfGpLcTNSzRU8TiDDS5ymx1

const Quote = styled.div`
  width: 100%;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  > :first-child {
    margin-bottom: 8px;
    > :first-child {
      font-weight: 600;
      font-family: 'Dancing Script', cursive;
      font-size: 25px;
      line-height: 25px;
    }
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > :last-child {
      cursor: pointer;
    }
  }
  > :nth-child(2) {
    box-shadow: 0 2px 1px rgba(0,0,0,0.2);
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    > :last-child {
    margin-left: auto;
    margin-top: 20px;
    font-style: italic; 
    }
  }

  ${props => props.self ?
      css`
        > :nth-child(2) {
          background-color: #DCF8C7;
        }
        transform: translateX(20px);
        ` : css`
        > :nth-child(2) {
          background-color: #FFF;
        }
        transform: translateX(-20px);
      `
}
`

const Seen = styled.div`
  color: white;
  margin-left: auto;
`

const MOTDHistory = (props) => {
  const { currentUser } = CurrentUserState.useContainer()
  const { allQuotes, loadMore, hasMore, loadingAllQuotes } = MessageOfTheDayState.useContainer()

  useEffect(() => {
  }, [])

  if (loadingAllQuotes) return <Loader />

  return <Wrapper>
    <Content>
      <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          loader={
            <div className="loader" key={0} style={{
              height: '220px',
              width: '100%'
            }}>
              <Loader />
            </div>
          }
        >
      {
        allQuotes.map(quote => 
          <Quote key={quote.id} self={
            quote.createdBy === currentUser.uid ||
            (currentUser.uid !== 'BQpXcjfGpLcTNSzRU8TiDDS5ymx1' && !quote.createdBy)
          }>
            <div>
              <div>{
                quote.createdBy === 'BQpXcjfGpLcTNSzRU8TiDDS5ymx1' ? "L'amoureuse" : "L'amoureux"
              }</div>
              <FormattedDate value={new Date(quote.date.seconds * 1000)} />

            </div>
            <div>
              <div><EditableText onEdited={val => null/* update(quote, { value: val }) */ }>{quote.value}</EditableText></div>
              <div>- <EditableText onEdited={val => null/* update(quote, { author: val }) */ }>{quote.author}</EditableText></div>
            </div>
            { quote.seen && <Seen>
              Vu le&nbsp;
              <FormattedDate value={new Date(quote.seenAt.seconds * 1000)} year='numeric' month='numeric' day='numeric' hour='numeric' minute='numeric' />
            </Seen> }
          </Quote>)
      }
      </InfiniteScroll>
    </Content>
  </Wrapper>
}

MOTDHistory.propTypes = {

}

MOTDHistory.defaultProps = {

}

export default MOTDHistory
