import React from 'react'
import PrivateLayout from '../../layouts/private'
import CreateMOTDPage from '../../components/pages/MessageOfTheDay/pages/create'

const CreateMessageOfTheDayPage = () => {
  return <PrivateLayout><CreateMOTDPage /></PrivateLayout>
}

CreateMessageOfTheDayPage.propTypes = {

}

CreateMessageOfTheDayPage.defaultProps = {

}

export default CreateMessageOfTheDayPage
