import React from 'react'
import PropTypes from 'prop-types'
import MessageOfTheDayState from './index.state'
import styled from 'styled-components'
import { FormattedDate } from 'react-intl'
import EditableText from '../../UI/EditableText'
import { Trash } from 'grommet-icons'
import Loader from '../../UI/Loader'

const Wrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`

const Nothing = styled.div`
  text-align: center;
  color: white;
  margin-top: 20px;
`

const Content = styled.div`
  > * {
    box-shadow: 0 2px 1px rgba(0,0,0,0.2);
    margin-bottom: 20px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const Quote = styled.div`
  width: 100%;
  > :first-child {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > :last-child {
      cursor: pointer;
    }
  }
  > :last-child {
    padding: 15px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    > :last-child {
      margin-top: 15px;
      font-style: italic;
      text-align: right; 
    }
  }
`

const ListMOTD = (props) => {
  const { loadingMyQuotes, myQuotes, update, remove } = MessageOfTheDayState.useContainer()

  if (loadingMyQuotes) return <Loader />

  if (myQuotes.length === 0) {
    return <Wrapper>
      <Nothing>
        Aucun message
      </Nothing>
    </Wrapper>
  }

  return <Wrapper>
    <Content>
      {
        myQuotes.map(quote => 
          <Quote key={quote.id}>
            <div>
              <FormattedDate value={new Date(quote.date.seconds * 1000)} />
              <Trash color='#fff' size='14px' onClick={() => remove(quote)} />
            </div>
            <div>
              <div><EditableText onEdited={val => update(quote, { value: val })}>{quote.value}</EditableText></div>
              <div>- <EditableText onEdited={val => update(quote, { author: val })}>{quote.author}</EditableText></div>
            </div>
          </Quote>)
      }
    </Content>
  </Wrapper>
}

ListMOTD.propTypes = {

}

ListMOTD.defaultProps = {

}

export default ListMOTD
