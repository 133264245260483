import React, { useState } from 'react'
import styled from 'styled-components'
import CurrentMOTD from './CurrentMOTD'
import ListMOTD from './ListMOTD'
import CreateMOTD from './CreateMOTD'
import MOTDHistory from './MOTDHistory'
import { Previous, Edit, History } from 'grommet-icons'
import { colors } from '../../../theme'
import { Link, navigate } from 'gatsby'
import MessageOfTheDayState from './index.state'

const Wrapper = styled.div`
  position: relative;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to left, ${colors.primary}, ${colors.secondary});
`

const Content = styled.div`
  max-width: 500px;
  margin: 0 auto;
  padding: 0 15px;
  flex: 1;
  width: 100%;
`

const Back = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const Actions = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  > * {
    height: 50px;
    width: 50px;
    background-color: rgba(0,0,0,0.2);
    color: #fff;
    border-radius: 8px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
  }

`

const Title = styled.div`
  font-family: 'Dancing Script', cursive;
  font-size: 39px;
  line-height: 39px;
  widows: 100%;
  color: white;
  text-align: center;
  margin-bottom: 45px;
  > :nth-child(2) {
    margin-right: 10px;
  }
`

const MessageOfTheDayLayout = ({ children }) => {
  const { myQuotes } = MessageOfTheDayState.useContainer()

  return <Wrapper>
      <Back onClick={() => window.history.back()}>
        <Previous color="#fff" size="30px" />
      </Back>
      <Actions>
      <div onClick={() => navigate('/message-du-jour/create')}>
        <div>{ myQuotes.length }</div>
      </div>
      <div onClick={() => navigate('/message-du-jour/history')}>
        <History color='#fff' />
      </div>
      </Actions>

      <Title>
        <span>Le Message du jour</span>
      </Title>
      <Content>
        { children }
      </Content>
  </Wrapper>
}

MessageOfTheDayLayout.propTypes = {

}

MessageOfTheDayLayout.defaultProps = {

}

export default MessageOfTheDayLayout
