import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.span`
  width: 100%;
  input {
    display: inline-block;
    width: 100%;
  }
  > :first-child {
    display: inline-block;
    outline: none;
  }
`

const Text = styled.span`
  width: 100%;
`

const EditableText = (props) => {
  const [editing, setEditing] = useState(false)
  const divRef = useRef()

  const onSubmit = async (e) => {
    e.preventDefault()
    await props.onEdited(divRef.current.innerText)
    setEditing(false)
  }

  if (editing) return <Wrapper>
    <div ref={divRef} contentEditable onKeyPress={e => e.which === 13 && onSubmit(e)} dangerouslySetInnerHTML={{ __html: props.children }} />
  </Wrapper>
  return <Text onClick={() => setEditing(true)}>{props.children}</Text>
}

EditableText.propTypes = {
}

EditableText.defaultProps = {
  onEdited: () => {}
}

export default EditableText
