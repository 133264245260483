import React, { useState } from "react"
import PropTypes from "prop-types"
import MessageOfTheDayState from "./index.state"
import styled, { css } from "styled-components"
import Typewriter from 'typewriter-effect'
import Loader from "../../UI/Loader"

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  color: white;
  > :first-child {
    opacity: 0;
  }
  > :nth-child(2) {
    position: absolute;
    top: 0;
    left: 15px;
    width: calc(100% - 30px);
    text-align: justify;
  }

  > :nth-child(3) {
    margin-top: 10px;
    align-self: flex-end;
    font-style: italic;
    opacity: 0;
    ${props =>
      props.typingOver &&
      css`
        opacity: 1;
      `}
  }
`

const CurrentMOTD = props => {
  const { loadingCurrent, quote } = MessageOfTheDayState.useContainer()
  const [typingOver, setTypingOver] = useState(false)

  if (loadingCurrent) return <Loader />
  if (!quote) {
    return <Wrapper>
      <div />
      <div style={{ color: '#fff', textAlign: 'center' }}>Oh, bah il n'y a pas encore de message 😞!</div>
    </Wrapper>
  }
  return (
    <Wrapper typingOver={typingOver}>
      <div>«{quote.value}»</div>
      <div>
        <Typewriter
          cursor="none"
          onInit={typewriter => {
            typewriter
              .callFunction(state => {
                state.elements.cursor.style.display = "none"
              })
              .changeDelay(40)
              .pause(1000)
              .typeString(`« ${quote.value} »`)
              .pause(1500)
              .callFunction(() => {
                setTypingOver(true)
              })
              .start()
          }}
        />
      </div>
      <div>- {quote.author}</div>
    </Wrapper>
  )
}

CurrentMOTD.propTypes = {}

CurrentMOTD.defaultProps = {}

export default CurrentMOTD
