import React from 'react'
import CreateMOTD from '../CreateMOTD'
import MessageOfTheDayLayout from '../layout'
import ListMOTD from '../ListMOTD'
import styled from 'styled-components'

const NotSeen = styled.div`
  padding-top: 80px;
  > :first-child {
    font-size: 32px;
    text-align: center;
    font-weight: bold;
    font-family: 'Dancing Script', cursive;
    color: #fff;
  }
`

const CreateMOTDPage = () => {
  return <MessageOfTheDayLayout>
    <CreateMOTD />
    <NotSeen>
      <div>Pas encore vus</div>
      <ListMOTD />
    </NotSeen>
  </MessageOfTheDayLayout>
}

CreateMOTDPage.propTypes = {

}

CreateMOTDPage.defaultProps = {

}

export default CreateMOTDPage
